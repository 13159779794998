class CardFormatService {
    constructor(client) {
      this.apiClient = client;
    }
  
    async createCardFormat(cardFormat) {
      return await this.apiClient.post(`cardformat`, cardFormat, {headers: {"Content-Type": "application/json"}});
    }

    async getCardFormats() {
      return await this.apiClient.get(`cardformat`);
    }

    async updateCardFormat(cardFormat) {
      return await this.apiClient.put(`cardformat`, cardFormat, {headers: {"Content-Type": "application/json"}})
    }

    async deleteCardFormats(cardFormatIDArray) {
      return await this.apiClient.delete(`cardformat`, {headers: {"Content-Type": "application/json"}, data: cardFormatIDArray })
    }

    async createEntityCardFormat(entityCardFormat) {
      return await this.apiClient.post(`entitycardformats`, entityCardFormat, {headers: {"Content-Type": "application/json"}})
    }

    async deleteEntityCardFormat(entityID, cardFormatID) {
      return await this.apiClient.delete(`entitycardformats/${entityID}/${cardFormatID}`)
    }

    async getEntityCardFormats(entityID) {
      return await this.apiClient.get(`entitycardformats/${entityID}`);
    }
  }
  
  export default CardFormatService;
