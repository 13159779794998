class HotelInterfaceService {
    constructor(apiClient) {
      this.client = apiClient;
    }

    async RequestDataReSync(entityID) {
        return await this.client.put(
          `HotelInterface/${entityID}/datasync`
        );
      }
  }
  
  export default HotelInterfaceService;
  