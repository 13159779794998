class AlertsService {
  constructor(client) {
    this.apiClient = client;
  }

  async getAlerts(cancelToken, entityID) {
    return await this.apiClient.get(
      `/alerts/${entityID}/v2?status=Claimed&status=NotYetClaimed`,
      { cancelToken }
    );
  }

  async getAlertsForFacilities(entityIDs, cancelToken) {
    return await this.apiClient.post(
      `/alerts/v3`,
      {
        EntityIDs: entityIDs,
        StatusTypes: [
          "NotYetClaimed",
          "Claimed"
        ]
      },
      {
        headers: { "Content-Type": "application/json" },
        cancelToken: cancelToken
      }      
    );
  }

  async unclaimAlert(entityID, alertID) {
    return await this.apiClient.patch(
      `/alerts/${entityID}/v2/${alertID}/unclaim`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async claimAlert(entityID, alertID, options) {
    return await this.apiClient.patch(
      `/alerts/${entityID}/v2/${alertID}/claim`,
      options,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async clearAlert(entityID, alertID, options) {
    return await this.apiClient.patch(
      `/alerts/${entityID}/v2/${alertID}/clear`,
      options,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async clearAlerts(entityID, options) {
    return await this.apiClient.put(
      `/alerts/${entityID}/v2/clearAll`,
      options,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async clearAlertsForFacilities(details) {
    return await this.apiClient.put(
      `/alerts/v3/clearAll`,
      details,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }
}

export default AlertsService;
