export class TreeService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getTreeStructure(entityId, limit) {
    return this.apiClient.get(`/entities/${entityId}?limit=${limit}`);
  }
}

export default TreeService;
