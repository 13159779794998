class CashierShiftsService {
    constructor(apiClient) {
      this.apiClient = apiClient;
    }
  
    async getCashierShifts(entityID) {
      return await this.apiClient.get(`cashiershifts/${entityID}`);
    }

    async getCashierShiftById(shiftID) {
      return await this.apiClient.get(`cashiershifts/getbyid/${shiftID}`);
    }

    async getCashierShiftByUserId(userID) {
      return await this.apiClient.get(`cashiershifts/getbyuserid/${userID}`);
    }

    async createCashierShift(shiftSettings) {
      return await this.apiClient.post(`cashiershifts`, shiftSettings, {
        headers: { "Content-Type": "application/json" },
      });
    }

    async endShift(shiftID, userID) {
      return await this.apiClient.patch(`cashiershifts`, { shiftID, userID }, {
        headers: { "Content-Type": "application/json" }
      });
    }
  }
  
  export default CashierShiftsService;
  