class PassThruService {
	constructor(client) {
		this.apiClient = client;
	}

	async getPassThruEntries(entityID, cancelToken) {
		return this.apiClient.get(`passthru/${entityID}`, {
      cancelToken: cancelToken,
    });
	}

	async createPassThruEntry(entityId, name) {
		return await this.apiClient.post("passthru", {
			name,
			entityId
		});
	}

	async deletePassThruEntries(entryId) {
		return await this.apiClient.delete("passthru",
		{
			headers: { "Content-Type": "application/json" },
			data: [entryId]
		});
	}
}
export default PassThruService;