import moment from "moment-timezone";

class MobilePaymentService {
  constructor(apiClient) {
    this.client = apiClient;
  }

  getClientTime() {
    var time = moment
      .utc(new Date())
      .local()
      .format();
    return time;
  }

  async getTicketDetails(ticketID) {
    return await this.client.get(`MobilePay/GetTicketDetails/${ticketID}`, {
      params: { clientTime: this.getClientTime() },
    });
  }

  async tryGetValidation(credentialReference, entityID, entryTime) {
    return await this.client.get(
      `MobilePay/Validation/${credentialReference}?entityID=${entityID}&entryTime=${entryTime}`
    )
  }

  async emailReceipt(ticketID, emailAddress) {
    return await this.client.post(
      `MobilePay/EmailReceipt/${ticketID}`,
      {},
      { params: { clientTime: this.getClientTime(), emailAddress: emailAddress } }
    );
  }

  async closeOut(ticketId) {
    return await this.client.post(`MobilePay/Payment/CloseOut`, {
      ticketID: ticketId
    })
  }

  async postPaymentRequest(ticketID, hostBase, totalToPay, externalService, mobilePaySessionID) {
    var time = this.getClientTime();
    return await this.client.post(
      `mobilepayment/requestpayment`,
      {
        ticketID: ticketID,
        hostBase: hostBase,
        totalToPay: totalToPay,
        ClientTime: time,
        externalService: externalService,
        mobilePaySessionID: mobilePaySessionID
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async postProcessPayment(ticketID, result) {
    var time = this.getClientTime();
    return await this.client.post(
      `MobilePay/Payment/Process`,
      {
        ticketID: ticketID,
        result: result,
        ClientTime: time,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }
  
  async sendSMSReceipt(SMSRequest) {
      return await this.client.post(`MobilePay/SMSReceipt`, SMSRequest, {headers: {"Content-Type": "application/json"}});
  }
  
  async updatePaymentStatus(ticketID) {
    return await this.client.post(`mobilepayment/paymentstatus/${ticketID}`, {
      ticketID: ticketID
    });
  }
}

export default MobilePaymentService;
