class DeviceStateService {
  constructor(client) {
    this.apiClient = client;
  }

  async retrieveState(entityID, cancelRequest) {
    return await this.apiClient.get(`device/status/${entityID}`, cancelRequest,
      {
        headers: {"Content-Type": "application/json"},
      }
    );
  }

}

export default DeviceStateService;
