import EntityService from "./EntityService";

class ValetAreaService extends EntityService {
  constructor(client) {
    super(client);
  }

  async getEntity(valetareaID) {
    return await this.apiClient.get(`valetarea/${valetareaID}`);
  }

  async onboardValetArea(valetarea) {
    return await this.apiClient.post(
      `valetarea`,
      valetarea,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

}

export default ValetAreaService;
