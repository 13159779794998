class HotelAccessHolderService {
    constructor(client) {
        this.apiClient = client;
    }
    
    async getHotelAccessHolderDetails(entityID, accessHolderID){
        return await this.apiClient.get(
            `hotelAccessHolders/${entityID}/${accessHolderID}`
        );
    }
}

export default HotelAccessHolderService;