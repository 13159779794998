class DashboardService {
  constructor(client) {
    this.apiClient = client;
  }

  async GetDashboardData(_entityID, date, timezone) {
    return await this.apiClient.get(
      `dashboard/data/${_entityID}?currentDate=${date}&timeZone=${timezone}`
    );
  }

  async GetNetRevenueForDay({ entityID, date, cancelToken }) {
    return await this.apiClient.get(
      `dashboard/widget/revenue/${entityID}/day`,
      {
        cancelToken,
        params: { startOfDayUTC: date },
      }
    );
  }

  async GetRevenueForDateRange({
    entityID,
    startOfMonthUTC,
    currentDayUTC,
    sevenDaysAgoUTC,
    cancelToken,
  }) {
    return await this.apiClient.get(`dashboard/widget/revenue/${entityID}`, {
      cancelToken,
      params: { startOfMonthUTC, currentDayUTC, sevenDaysAgoUTC },
    });
  }

  async GetRevenueForFacilities( entityIDs,date, cancelToken ) {
    return await this.apiClient.get('dashboard/widget/revenue/revenueSummary', {
      cancelToken,
      params: { entityIDs, startOfDayUTC: date,}
    });
  }
}

export default DashboardService;
