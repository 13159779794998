class CallCenterService {
  constructor(client) {
    this.apiClient = client;
  }

  async fetchQueue(entityID) {
    return this.apiClient.get(`callcenter/calls/${entityID}/queued`);
  }

  async fetchQueueByUserID(userID) {
    return this.apiClient.get(`callcenter/calls/user/${userID}/queued`);
  }

  async queueCall(queuedCall) {
    return this.apiClient.post(`callcenter/calls/`, queuedCall, {
      headers: { "Content-Type": "application/json" },
    });
  }
  async answerCall(callID, callActivity) {
    return this.apiClient.put(
      `callcenter/calls/${callID}/answer`,
      callActivity,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async endCall(callID, callActivity) {
    return this.apiClient.put(`callcenter/calls/${callID}/end`, callActivity, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateDetails(callID, details) {
    return this.apiClient.put(`callcenter/calls/${callID}/details`, details, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async unarchiveTicket( ticketID) {
    return this.apiClient.put(`callcenter/unarchive/ticket/${ticketID}`,
    `"${ticketID}"`,
    {
      headers: { "Content-Type": "application/json" },
    });
  }

  async unarchivePrepaidPass(contractID, accessHolderID, credentialReference) {
    return this.apiClient.put(`callcenter/unarchive/prepaidpass/${contractID}/${accessHolderID}`,
    `"${credentialReference}"`,
    {
      headers: { "Content-Type": "application/json" },
    });
  }

  async search(entityID, searchTerm, token) {
    return this.apiClient.get(`callcenter/search/${entityID}`, {
      cancelToken: token,
      params: { searchTerm },
    });
  }

  async searchV2(entityID, searchTerm, searchCategory) {
    return this.apiClient.get(`callcenter/search/v2/${entityID}`, {
      params: { searchTerm, searchCategory }
    })
  }

  async createAction(callID, action) {
    //console.log("CallCenter createAction", action);
    return this.apiClient.post(`callcenter/calls/${callID}/actions`, action, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getLastScreenCapture(deviceID) {
    return this.apiClient.get(`callcenter/captures/${deviceID}`, {
      responseType: "blob",
    });
  }

  async getLicensePlateCaptureImage(lprId) {
    return this.apiClient.get(`reads/${lprId}/captureimage`, {
      responseType: "blob",
    });
  }

  async getLicensePlateContextImage(lprId) {
    return this.apiClient.get(`reads/${lprId}/contextimage`, {
      responseType: "blob",
    });
  }

  async getDeviceTransaction(deviceID, token) {
    return this.apiClient.get(`callcenter/device/transaction/${deviceID}`, {
      cancelToken: token
    });
  }

}

export default CallCenterService;
