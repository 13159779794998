class TicketService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  retrieveTicketById(ticketID) {
    return this.apiClient.get(`tickets/${ticketID}`);
  }

  searchForTicketsSlim({facilityID, searchTerm, cancelToken}) {
    return this.apiClient.get("tickets/v2/search/slim", {
      cancelToken,
      params: {
        entityID: facilityID,
        searchTerm: searchTerm
      },
    });
  }

  searchForTicketsWithValidations({ facilityID, searchTerm, searchType, cancelToken }) {
    return this.apiClient.get("ticketsearch", {
      cancelToken,
      params: {
        entityID: facilityID,
        searchTerm: searchTerm,
        searchType: searchType
      },
    });
  }

  async updateTicket(ticket) {
    return await this.apiClient.put("tickets", ticket, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async createTicket(ticket) {
    return await this.apiClient.post("tickets", ticket, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async negotiateTicket(ticket) {
    return await this.apiClient.post("tickets/negotiate", ticket, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getTicket(ticketID, entityID) {
    return await this.apiClient.get(`tickets/${ticketID}?entityID=${entityID}`);
  }

  async validateTicket(ticketID, entryTime) {
    return await this.apiClient.get(`tickets/${ticketID}/validate`, {
      params: { entryTime: entryTime },
    });
  }
}
export default TicketService;
