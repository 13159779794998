class VehicleService {
  constructor(apiClient) {
    this.client = apiClient;
  }

  async GetVehiclesForAccessHolder(accessHolderID, limit, offset, searchTerm) {
    return await this.client.get(`vehicles/accessholder/${accessHolderID}`, {
      params: { limit, offset, searchTerm },
    });
  }

  async CreateVehicleForAccessHolder(accessHolderID, vehicle) {
    return await this.client.post(
      `vehicles/accessholder/${accessHolderID}`,
      vehicle,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async UpdateVehicleForAccessHolder(accessHolderID, vehicle) {
    return await this.client.put(
      `vehicles/accessholder/${accessHolderID}/vehicle`,
      vehicle,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async RemoveVehicleFromAccessHolder(accessHolderID, vehicleID, credentialID) {
    return await this.client.put(
      `vehicles/accessHolder/${accessHolderID}/removevehicle?credentialID=${credentialID}`,
      `"${vehicleID}"`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async getVehicleColors() {
    return await this.client.get(`vehicledetails/color`);
  }

  async getVehicleMakes() {
    return await this.client.get(`vehicledetails/make`);
  }

  async getVehicleModels(makeID) {
    return await this.client.get(`vehicledetails/model/${makeID}`);
  }
}

export default VehicleService;
