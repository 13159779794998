class GroupService {
  constructor(client) {
    this.apiClient = client;
  }

  async getGroups(groupType, entityID, limit, offset, searchTerm) {}

  async getDefaultGroups(facilityID) {
    return await this.apiClient.get("groups/default", {
      params: { facilityID },
    });
  }
}

export default GroupService;
