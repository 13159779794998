class FeatureFlagService {
	constructor(client) {
		this.apiClient = client;
	}

	async getSingleFeatureFlag(entityId, featureFlagPath) {
		return await this.apiClient.get(`bff/features/${entityId}/${featureFlagPath}`);
	}

	async getFeatureFlags(entityId) {
		return await this.apiClient.get(`bff/features/${entityId}`);
	}

	async getOrphanFlags(entityId) {
		return await this.apiClient.get(`features/${entityId}/orphans`);
	}

	async getAllFeaturesEntityAdmin(entityId) {
		return await this.apiClient.get(`bff/features/${entityId}/grouped`);
	}

	async upsertFlags(entityId, changes) {
		return await this.apiClient.post(`bff/features/${entityId}`, changes, {
			headers: { "Content-Type": "application/json" },
		});
	}
}
export default FeatureFlagService;