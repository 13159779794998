class ImportService {
    constructor(client) {
        this.apiClient = client;
    }

    async getImportsByUserId(userId){
        return await this.apiClient.get(
            `imports/user/${userId}`
        )
    }

    async getImportsByFacilityId(facilityId){
        return await this.apiClient.get(
            `imports/facility/${facilityId}`
        )
    }

    async uploadFile(userId, file, facilityId){
        var formData = new FormData();
        formData.append("name", "FILENAME");
        formData.append("file", file);
        return await this.apiClient.post(
            `import/upload/${userId}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "facilityid": `${facilityId}`
                },
                body: formData
            }
        )
    }
}

export default ImportService;