class AccessGroupService {
  constructor(apiClient) {
    this.client = apiClient;
  }

  async GetAccessGroups(facilityID, limit, offset, searchTerm, groupType) {
    return await this.client.get(`accessgroups/${facilityID}`, {
      params: { limit, offset, searchName: searchTerm, groupType},
    });
  }

  async GetAccessGroup(facilityID, accessGroupID) {
    return await this.client.get(`accessgroups/${facilityID}/${accessGroupID}`);
  }

  async GetAssignedAccessGroups(accessHolderID, limit, offset, searchTerm) {
    return await this.client.get(
      `accessgroups/accessholder/${accessHolderID}`,
      {
        params: { limit, offset, searchTerm },
      }
    );
  }

  async GetAssignedAccessHolders(accessGroupID) {
    return await this.client.get(`accessgroups/groupholders/${accessGroupID}`);
  }

  async GetAssignedAccessHoldersByGroups(accessGroupIDs) {
    return await this.client.post("accessgroups/groupids", accessGroupIDs, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async CreateAccessGroup(facilityID, accessGroup) {
    return await this.client.post(`accessgroups/${facilityID}`, accessGroup, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async UpdateAccesGroup(accessGroupID, accessGroup) {
    return await this.client.put(`accessgroups/${accessGroupID}`, accessGroup, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async DeleteAccessGroup(facilityID, accessGroupID) {
    return await this.client.delete(
      `accessgroups/${facilityID}/${accessGroupID}`
    );
  }

  async AssignAccessHolderToGroup(accessGroupID, accessHolderID) {
    return await this.client.put(
      `accessgroups/${accessGroupID}/accessholder/${accessHolderID}/assign`
    );
  }

  async UnassignAccessHolderFromGroup(accessGroupID, accessHolderID) {
    return await this.client.put(
      `accessgroups/${accessGroupID}/accessholder/${accessHolderID}/unassign`
    );
  }

  async GetThresholds(accessGroupID) {
    return await this.client.get(
      `accessgroupthresholds/${accessGroupID}`
    );
  }
}

export default AccessGroupService;
