class CardSystemCodesService {
  constructor(client) {
    this.apiClient = client;
  }

  async createCardSystemCodes(entityID, cardSystemCode) {
    return await this.apiClient.post(`systemcodes/${entityID}/${cardSystemCode}`, null, { headers: { "Content-Type": "application/json" } })
      .catch((e) => {
        if (e.response.status == 409) {
          throw new Error(e.response.data);
        } else {
          throw e;
        }
      });
  }

  async getCardSystemCodes(entityID) {
    return await this.apiClient.get(`systemcodes/${entityID}`);
  }

  async deleteCardSystemCode(entityID, cardSystemCode) {
    return await this.apiClient.delete(`systemcodes/${entityID}/${cardSystemCode}`, null, { headers: { "Content-Type": "application/json" } });
  }

  async getCardSystemCodesForDropdown(entityID) {
    const response = await this.apiClient.get(`systemcodes/${entityID}`);
    return response.data.cardSystemCodes.map(cardCode=> ({ name: cardCode.code, value: cardCode.code }));
  }
}

export default CardSystemCodesService;
