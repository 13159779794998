class LogService {
  constructor(client) {
    this.apiClient = client;
  }

  async getLogs(deviceID) {
    return this.apiClient.get(`logs/devices/${deviceID}`);
  }

  async downloadLog(key) {
    return this.apiClient.get("logs/devices/download", {
      params: { key },
      responseType: "arraybuffer",
    });
  }
}

export default LogService;
