class settingsService {
  constructor(client) {
    this.apiClient = client;
  }

  async getSettings(entityId, includeType = false) {
    return this.apiClient.get(`settings/${entityId}`, {
      params: { includeType },
    });
  }

  async getSettingByName(entityId, settingName) {
    return this.apiClient.get(`settings/${entityId}/${settingName}`);
  }

  async deleteSettingByName(entityId, settingName) {
    return this.apiClient.delete(`v2/settings/${entityId}/${settingName}`);
  }

  async updateSetting(entityId, setting) {
    console.log("settings update",entityId, setting);
    const request = {
      EntityId: entityId,
      SettingName: setting.name,
      SettingValue: setting.value,
    };
    return this.apiClient.put("settings", request, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateSettingsBulk(settings) {
    console.log("settings bulk", settings);
    return this.apiClient.put("v2/settings/bulk", settings, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getChildrenSettings(entityId) {
    return this.apiClient.get(`settings/${entityId}/children`);
  }
}

export default settingsService;
