import EntityService from "./EntityService";

class FacilityService extends EntityService {
  constructor(client) {
    super(client);
  }

  async createEntity(parentEntityID, entity) {
    entity.name = entity.entityname;
    entity.type = entity.entitytype;
    return await this.apiClient.post(`facility/${parentEntityID}`, entity, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async onboardFacility(parentEntityID, facility) {
    return await this.apiClient.post(
      `facility/onboard/${parentEntityID}`,
      facility,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

    async getEntities(parentEntityID, offset, limit, searchTerm) {
        return await this.apiClient.get(`facility/${parentEntityID}`, {
            params: { offset, limit, searchTerm },
        });
    }

  async getEntity(parentEntityID, facilityID) {
    return await this.apiClient.get(`facility/${parentEntityID}/${facilityID}`);
  }

  async updateEntity(entity) {
    if (!entity.name || entity.name === "") entity.name = entity.entityname;
    if (!entity.facilityID || entity.facilityID === "")
      entity.facilityID = entity.entityid;
    return await this.apiClient.put("facility", entity, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteEntities([entityids]) {
    return await this.apiClient.patch("facility", [entityids], {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getFacilityByTicket(ticketId) {
    return await this.apiClient.get(`facility/ticket/${ticketId}`);
  }
  
  async getFacilityActivity(searchTerm) {
    return await this.apiClient.get(`facility/activity`, {
      params: { searchTerm },
    });
  }
}

export default FacilityService;
