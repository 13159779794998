import Moment from "moment";

/*
  Rate
  {
      Guid RateID
      Guid EntityID
      string Name
      string Description
      List<RateApplication> RateApplications
      string RateApplicationsJSON
  }
}
*/

export class RateService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getRateAssignedToEntity(entityId) {
    return this.apiClient.get(`rates/${entityId}/rate`);
  } // return rate object
  assignRateToEntity(entityId, rateId) {
    return this.apiClient.put(`rates/${entityId}/rate?rateId=${rateId}`);
  }
  assignRateToEntityV2(entityId, details) {
    return this.apiClient.put(`rates/v2/${entityId}/rate`, details);
  } // returns nothing
  unassignRateFromEntity(entityId) {
    return this.apiClient.delete(`rates/${entityId}/rate`);
  } // returns nothing

  async getAvailableRates(entityId, summary) {
    return await this.apiClient.get(
      `rates/${entityId}/rates?summary=${summary || 0}`
    );
  } // returns array of rate objects

  async getRatesForEntity(entityId) {
    return await this.apiClient.get(`rates/v2/entity/${entityId}`);
  }

  createRate(entityId, rate) {
    return this.apiClient.post(`rates/${entityId}/rates`, rate);
  } // returns rateid
  updateRate(entityId, rate) {
    return this.apiClient.put(`rates/${entityId}/rates`, rate);
  } // returns rateid


  async createRateWithResult(rate) {
    return this.apiClient.post("rates/v2", rate);
  }
  async updateRateWithResult(rate) {
    return this.apiClient.put("rates/v2", rate);
  }


  deleteRate(rateId) {
    return this.apiClient.delete(`rates/v2/${rateId}`);
  } // returns nothing
  apply(details, entryTime, exitTime) {
    return this.apiClient.post(
      `/rates/apply?entryTime=${entryTime}&exitTime=${exitTime}`,
      details
    );
  } // returns a receipt

  // todo: change this to use calculate direct (once calculate direct is implemented)
  applyRateForPreview(
    rateApps,
    startTime,
    durationRows,
    interval,
    entryColumns
  ) {
    return this.apiClient.post("/rates/apply", rateApps, {
      params: {
        entryTime: startTime,
        rows: durationRows,
        exitTimeDelta: interval * 60,
        entryTimeDelta: interval * 60,
        columns: entryColumns,
      },
      headers: { "Content-Type": "application/json" },
    });
  }

  // todo: remove unused function
  getCurrentRates(entityId) {
    return this.apiClient.get(`/rates/${entityId}/applications`);
  }

  // todo: remove unused function
  createNewRateApplication(entityId, rateApplications) {
    return this.apiClient.post(
      `/rates/${entityId}/applications`,
      rateApplications
    );
  }

  // todo: remove unused function
  getOverlayRates(entityId, startDate, endDate) {
    const params = {
      effectiveStartDate: startDate,
      effectiveEndDate: endDate,
    };
    return this.apiClient.get(`/rates/${entityId}/applications`, {
      params,
    });
  }

  // todo: remove unused function
  createOverlayRateApplication(entityId, newOverlay) {
    const request = [
      {
        rateTitle: newOverlay.title,
        rateRecords: [
          {
            type: 3,
            price: parseInt(newOverlay.notes),
            currencyAbbreviation: "USD",
            duration: (newOverlay.endDate - newOverlay.startDate) / 1000,
            durationMultiplier: 60,
            durationName: "minute",
            maxLimit: (newOverlay.endDate - newOverlay.startDate) / 1000,
            carryOverflow: false,
          },
        ],
        effectiveStartDate: newOverlay.startDate,
        effectiveEndDate: newOverlay.endDate,
        cadence: this.createCadence(newOverlay.startDate),
        priority: 2,
        entryOnly: false,
        entryDuration: (newOverlay.endDate - newOverlay.startDate) / 1000,
        exitStart: null,
        exitEnd: null,
        applyPartially: false,
        forcePrice: false,
        overlaidRate: true,
        concatenatedRate: false,
      },
    ];

    return this.apiClient.post(`/rates/${entityId}/applications`, request, {
      headers: { "Content-Type": "application/json" },
    });
  }

  // todo: remove unused function
  deleteOverlayApplication(entityId, deleteIds) {
    const request = [deleteIds];
    return this.apiClient.delete(`/rates/${entityId}/applications`, {
      headers: { "Content-Type": "application/json" },
      data: request,
    });
  }

  // todo: remove unused function
  updateOverlayApplications(entityId, request) {
    return this.apiClient.put(`/rates/${entityId}/applications`, request, {
      headers: { "Content-Type": "application/json" },
    });
  }

  GetAssignedRatesByRateID(rateID) {
    return this.apiClient.get(`/rates/${rateID}/assignedRates`);
  }

  // todo: remove unused function
  createCadence(startDate) {
    let date = startDate;
    if (date instanceof Moment) {
      date = date._d;
    }
    return `${date.getMinutes()} ${date.getHours()} ${date.getDate()} ${date.getMonth()} ${date.getDay()}`;
  }

  /**
   * OBSOLETE
   */
  async CalculateRatePOEAttendedCashier(timeZone, poeID, validations) {
    const request = {
      TZ: timeZone,
      PoeID: poeID,
      Validations: validations,
    };

    return await this.apiClient.post("attendedcashier/rates/poe", request, {
      headers: { "Content-Type": "application/json" },
    });
  }

  /**
   * OBSOLETE
   */
  async CalculateRatePOERoamingCasher(timeZone, poeID, validations) {
    const request = {
      TZ: timeZone,
      PoeID: poeID,
      Validations: validations,
    };

    return await this.apiClient.post("roamingcashier/rates/poe", request, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async CalculateWithTaxes(calculateRate, bypassGrace, isLostTicket) {
    return this.apiClient.post(
      `/rates/calculatewithtaxes?bypassGrace=${bypassGrace}&isLostTicket=${isLostTicket}`,
      calculateRate,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async CalculateWithTaxesPOERoamingCashier(
    calculateRate,
    bypassGrace,
    isLostTicket
  ) {
    return this.apiClient.post(
      `/ratesroamingcashier/calculatewithtaxes?bypassGrace=${bypassGrace}&isLostTicket=${isLostTicket}`,
      calculateRate,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async CalculateWithTaxesPOEAttendCashier(
    calculateRate,
    bypassGrace,
    isLostTicket
  ) {
    return this.apiClient.post(
      `/ratesattendedcashier/calculatewithtaxes?bypassGrace=${bypassGrace}&isLostTicket=${isLostTicket}`,
      calculateRate,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  /**
   * OBSOLETE
   */
  async CalculateForCredentialRoamingCashier(credentialID) {
    return this.apiClient.get(`/ratesroamingcashier/calculate/${credentialID}`);
  }

  /**
   * OBSOLETE
   */
  async CalculateForCredentialAttendedCashier(credentialID) {
    return this.apiClient.get(
      `/ratesattendedcashier/calculate/${credentialID}`
    );
  }

  async CalculateCashierRateForCredential(request) {
    return this.apiClient.post("/cashier/credential/calculate/rate", request, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async CalculateCashierRatePOE(request) {
    return this.apiClient.post("/cashier/poe/calculate/rate", request, {
      header: { "Content-Type": "application/json" },
    });
  }
}

export default RateService;
