import axios from "axios";
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoRefreshToken
} from "amazon-cognito-identity-js";
//import polly from "polly-js";
import querystring from "querystring";

class AuthService {
  constructor(client) {
    this.poolDetails = new CognitoUserPool({
      UserPoolId: process.env.COGNITO_USER_POOL_ID ?? "us-east-2_xxxxxxxxx", // default format if empty
      ClientId: process.env.COGNITO_CLIENT_ID ?? "xxxxxxxxxxxxxxxxxxxxxxxxx" // default format if empty
    });
    this.cognitoUser = this.poolDetails.getCurrentUser();
    this.mobilePayAuthProvider =
      process.env.COGNITO_MOBILE_PAY_AUTH_PROVIDER ??
      "xxxxxxxxxxxxx.auth.us-east-2.amazoncognito.com/oauth2/token";
    this.mobilePayClientId =
      process.env.COGNITO_MOBILE_PAY_CLIENT_ID ?? "xxxxxxxxxxxxxxxxxxxxxxxxx";
    this.mobilePayClientSecret =
      process.env.COGNITO_MOBILE_PAY_CLIENT_SECRET ??
      "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx";
    this.apiClient = client;
  }

  async getPermissions(userID, facilityID = undefined) {
    try {
      if (!facilityID) {
        return await this.apiClient.get(`accounts/v2/permissions/${userID}`);
      } else {
        return await this.apiClient.get(
          `accounts/v2/permissions/${userID}?entityID=${facilityID}`
        );
      }
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  refreshUserToken() {
    if(this.cognitoUser == null) return;

    return new Promise((resolve, reject) => {
      this.cognitoUser.getSession((error, session) => {
        if (session) {
          const formattedRefreshToken = new CognitoRefreshToken({
            RefreshToken: session.refreshToken?.token ?? ""
          });
          this.cognitoUser.refreshSession(
            formattedRefreshToken,
            (err, session) => {
              if (session) {
                resolve(session.getIdToken().getJwtToken());
              } else {
                reject(err);
              }
            }
          );
        } else {
          reject(error);
        }
      });
    });
  }

  refreshUserSession() {
    return new Promise((resolve, reject) => {
      this.cognitoUser.getSession((error, session) => {
        if (session) {
          const formattedRefreshToken = new CognitoRefreshToken({
            RefreshToken: session.refreshToken?.token ?? ""
          });
          this.cognitoUser.refreshSession(
            formattedRefreshToken,
            (err, session) => {
              if (session) {
                resolve(session);
              } else {
                reject(err);
              }
            }
          );
        } else {
          reject(error);
        }
      });
    });
  }

  retrieveUser() {
    if (this.cognitoUser) {
      return new Promise((resolve, reject) => {
        this.cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err);
          }
          resolve(session);
        });
      });
    }
    return Promise.reject();
  }

  signInUser(username, password) {
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    });
    this.cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.poolDetails
    });

    return new Promise((resolve, reject) => {
      this.cognitoUser.authenticateUser(authDetails, {
        onSuccess: result => {
          resolve(result);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          this.userAttributes = userAttributes;
          reject({ type: "newPasswordRequired", requiredAttributes });
        },
        onFailure: function(error) {
          reject({ type: "failure", error });
        }
      });
    });
  }
  completeNewPasswordChallenge(newPassword, attributes) {
    delete this.userAttributes.email_verified;
    delete this.userAttributes.phone_number_verified;
    delete this.userAttributes.email;
    delete this.userAttributes.phone_number;

    return new Promise((resolve, reject) => {
      this.cognitoUser.completeNewPasswordChallenge(
        newPassword,
        { ...this.userAttributes, ...attributes },
        {
          onFailure: error => reject(error),
          onSuccess: result => {
            resolve(result);
          }
        }
      );
    });
  }
  forgotPassword(username) {
    this.cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.poolDetails
    });
    return new Promise((resolve, reject) => {
      this.cognitoUser.forgotPassword({
        onFailure: error => reject(error),
        onSuccess: result => {
          resolve(result);
        }
      });
    });
  }
  confirmPassword(verificationCode, newPassword) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure: error => reject(error),
        onSuccess: result => {
          resolve(result);
        }
      });
    });
  }
  handleLogout() {
    if (this.cognitoUser) this.cognitoUser.signOut();
  }

  getMobilePayAuthToken() {
    return axios
      .post(
        this.mobilePayAuthProvider,
        querystring.stringify({
          grant_type: "client_credentials",
          client_id: this.mobilePayClientId,
          client_secret: this.mobilePayClientSecret
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
      .then(function(response) {
        if (response.status == 200) {
          let authToken = response.data.access_token;
          return authToken;
        } else {
          return "";
        }
      })
      .catch(err => {
        return "";
      });
  }

  getClientIdAndSecret = async (parentEntityId, deviceId) => {
    const device = {
      DeviceID: deviceId,
      FacilityID: parentEntityId
    };
    return await this.apiClient.post("/provision/device", device, {
      headers: { "Content-Type": "application/json" }
    });
  };

  getClientAndSecretForThirdParty = async subscriberId => {
    const thirdparty = {
      ID: subscriberId
    };
    return await this.apiClient.post("/provision/thirdparty", thirdparty, {
      headers: { "Content-Type": "application/json" }
    });
  };

  blackListSubscriber = async subscriberID => {
    return await this.apiClient.post(
      `/authenticate/blacklistsubscriber/${subscriberID}`,
      "",
      {
        headers: { "Content-Type": "application/json" }
      }
    );
  };
}

export default AuthService;
