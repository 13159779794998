class CountryService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async getCountries() {
    return await this.apiClient.get('countries');
  }

  async getSubdivisions(countryID) {
    return await this.apiClient.get(`countries/subdivisions/${countryID}`)
  }
}

export default CountryService;