class MediaService {
  constructor(client) {
    this.apiClient = client;
  }

  async createMedia(media) {
    return await this.apiClient.post("/media", media, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateMedia(media) {
    return await this.apiClient.put("/media", media, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteMedia(mediaId) {
    return await this.apiClient.delete(`/media/${mediaId}`);
  }

  async getMediaList(entityId, parentEntityID, limit = 10, offset = 0) {
    const mediaPath = parentEntityID
      ? `${parentEntityID}/${entityId}`
      : entityId;
    return await this.apiClient.get(`/media/entityId/${mediaPath}`, {
      params: { limit, offset },
    });
  }

  async getMediaLogo(mediaId) {
    return await this.apiClient.get(`/media/${mediaId}`);
  }
}

export default MediaService;
