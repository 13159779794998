class ScheduleReportService {
    constructor(client) {
      this.apiClient = client;
    }
  
    async addStartOfDayWeek(formattedData) {
      return await this.apiClient.post(`ScheduleReport/StartOfDayWeek`, formattedData, {headers: {"Content-Type": "application/json"}})
    }

    async getStartOfDayWeek(entityID) {
      return await this.apiClient.get(`ScheduleReport/StartOfDayWeek/${entityID}`);
    }
  }
  
  export default ScheduleReportService;
