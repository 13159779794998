class TransactionManagerService {
  constructor(apiClient) {
    this.client = apiClient;
  }

  async createPaymentActivity(credentialId, deviceId, paymentTendered) {
    return await this.client.put("paymentstendered", paymentTendered, {
      params: { credentialId, deviceId },
      headers: { "Content-Type": "application/json" },
    });
  }

  async bulkCreatePayments(bulkPayment) {
    return await this.client.post("paymentstendered/bulk", bulkPayment, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async createParkingActivity(parkingActivity) {
    return await this.client.put(`parkingactivity`, parkingActivity, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async createTransientParkingTransaction(transactionInfo) {
    return await this.client.post("parkingtransactions", transactionInfo, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getAccessHolderHistory(accessHolderID, recordCount) {
    return await this.client.get(
      `parkingactivity/v2/accessholder/${accessHolderID}`,
      { params: { recordCount } }
    );
  }
}

export default TransactionManagerService;
