class routineService {
  constructor(client) {
    this.apiClient = client;
  }

  async getHistoryForRoutine(routineId, limit) {
    return this.apiClient.get(`events/history/${routineId}`, {
      params: { limit: limit },
    });
  }
}

export default routineService;
