class ContractService {
  constructor(client) {
    this.apiClient = client;
  }

  async getAccessHolderContract(accessHolderID, contractID) {
    return await this.apiClient.get(
      `/accessHolders/${accessHolderID}/contract/${contractID}`
    );
  }

  async getAccessHolder(facilityID, accessHolderID) {
    return await this.apiClient.get(
      `accessHolders/${facilityID}/${accessHolderID}`
    );
  }

  async getAccessHolders(
    facilityID,
    limit,
    offset,
    searchTerm,
    credentialType,
    status,
    mode,
    group,
    contractID
  ) {
    return await this.apiClient.get(`accessHolders/${facilityID}`, {
      params: {
        limit,
        offset,
        searchTerm,
        credentialType,
        status,
        mode,
        group,
        contractID,
      },
    });
  }

  async createAccessHolder(accessHolder) {
    return await this.apiClient.post("accessHolders", accessHolder, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateAccessHolderStatus(accessHolderID, change) {
    return this.apiClient.patch(
      `accessHolders/${accessHolderID}/status`,
      { status: change.status, reason: change.reason },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async updateAccessHolderMode(accessHolderID, mode) {
    return this.apiClient.patch(`accessHolders/${accessHolderID}/mode`, null, {
      params: { mode: mode },
    });
  }

  async updateAccessHolderNestOverride(accessHolderID, nestOverride) {
    return this.apiClient.patch(`accessHolders/${accessHolderID}/nestOverride`, null, {
      params: { nestOverride: nestOverride },
    });
  }

  async updateAccessHolder(accessHolder) {
    return await this.apiClient.put("accessHolders", accessHolder, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async syncAccessHolderStatusByGroup(syncStatus) {
    return await this.apiClient.put("accessHolders/sync", syncStatus, {
      headers: { "Content-Type": "application/json" },
    });
  }
 
  async updateAccessHolderDates(facilityID, contractID, startDate, endDate) {
    return await this.apiClient.put(
      `accessHolders/contract/dates/${facilityID}/${contractID}?startDate=${startDate}${
        !endDate ? "" : `&endDate=${endDate}`
      }`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async deleteAccessHolder(accessHolderID) {
    return await this.apiClient.delete(`accessHolders/${accessHolderID}`);
  }

  async deleteParkers(contractID, parkerIDs) {
    return await this.apiClient.patch(
      `contracts/${contractID}/parkers`,
      parkerIDs,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async createContract(contract) {
    return await this.apiClient.post("contracts", contract, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateContract(contract) {
    return await this.apiClient.put("contracts", contract, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getContract(facilityID, contractID) {
    return await this.apiClient.get(`contracts/${facilityID}/${contractID}`);
  }

  async getContracts(entityID, limit, offset, searchTerm) {
    return await this.apiClient.get(`contracts/${entityID}`);
  }

  async getContractRate(rateSystemID) {
    return await this.apiClient.get(`rates/blob/${rateSystemID}`);
  }

  async deleteContract(entityID, contractID) {
    return await this.apiClient.delete(
      `contracts/${entityID}/${contractID}/delete`
    );
  }

  async deleteContracts(contractIDs) {
    return await this.apiClient.patch("contracts", contractIDs, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getFundBalance(entityID, contractID) {
    return await this.apiClient.get(
      `contracts/funds/${entityID}/${contractID}`
    );
  }

  async updateFundBalance(entityID, contractID, request) {
    return await this.apiClient.post(
      `contracts/funds/${entityID}/${contractID}`,
      request,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async getContractDetails(entityID, contractID, typeID) {
    return await this.apiClient.get(
      `contracts/details/${entityID}/${contractID}/${typeID}`
    );
  }
  
  async createContractDetails(entityID, contractID, typeID, request) {
    return await this.apiClient.post(
      `contracts/details/${entityID}/${contractID}/${typeID}`,
      request,
      {
        headers: { "Content-Type": "application/json" },
      }
      );
  }
  
  async updateContractDetails(entityID, contractID, typeID, request) {
    return await this.apiClient.put(
      `contracts/details/${entityID}/${contractID}/${typeID}`,
      request,
      {
        headers: { "Content-Type": "application/json" },
      }
      );
    }
    
      async getCreditCardOnFile(contractID) {
        return await this.apiClient.get(
          `contracts/creditcardonfile/${contractID}`
        );
      }
}

export default ContractService;
