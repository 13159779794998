export default class CashierService {
  constructor(client) {
    this.apiClient = client;
  }

  async getRoamingCCTerminals(entityID) {
    return this.apiClient.get(`roamingcreditcardterminal/${entityID}`);
  }
  async createRoamingCCTerminal(roamingCCTerminal) {
    return this.apiClient.post("roamingcreditcardterminal", roamingCCTerminal, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateRoamingCCTerminal(roamingCCTerminal) {
    return this.apiClient.put("roamingcreditcardterminal", roamingCCTerminal, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteRoamingCCTerminal(roamingCCTerminalID) {
    return this.apiClient.delete(
      `roamingcreditcardterminal/${roamingCCTerminalID}`
    );
  }

  async postCashierPayment(payment) {
    return this.apiClient.post("Cashier/Payment/Credential", payment, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async postPOEPayment(payment) {
    return this.apiClient.post("Cashier/Payment/POE", payment, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async startTransaction(startRequest) {
    return this.apiClient.post(
      "cashier/paymentprocessing/start",
      startRequest,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async processCreditPayment(creditRequest) {
    return this.apiClient.post(
      "cashier/paymentprocessing/process/credit",
      creditRequest,
      {
        header: { "Content-Type": "application/json" },
      }
    );
  }

  async processCashPayment(cashRequest) {
    return this.apiClient.post(
      "cashier/paymentprocessing/process/cash",
      cashRequest,
      {
        header: { "Content-Type": "application/json" },
      }
    );
  }

  async cancelTransaction(cancelRequest) {
    return this.apiClient.post(
      "cashier/paymentprocessing/cancel/transaction",
      cancelRequest,
      {
        header: { "Content-Type": "application/json" },
      }
    );
  }

  async cancelCredit(cancelCreditRequest) {
    return this.apiClient.post(
      "cashier/paymentprocessing/cancel/credit",
      cancelCreditRequest,
      {
        header: { "Content-Type": "application/json" },
      }
    );
  }

  async completeTransaction(cashierID) {
    return this.apiClient.post(
      "cashier/paymentprocessing/complete",
      {
        cashierID,
      },
      { header: { "Content-Type": "application/json" } }
    );
  }

  async clearActiveTransaction(cashierID) {
    return this.apiClient.post(
      "cashier/paymentprocessing/clear",
      {
        cashierID,
      },
      { header: { "Content-Type": "application/json" } }
    );
  }

  async claimCashierValidation(validationRequest) {
    return this.apiClient.post(
      "cashier/payment/validation/claim",
      validationRequest,
      {
        header: { "Content-Type": "application/json" },
      }
    );
  }

  async unclaimCashierValidation(validationRequest) {
    return this.apiClient.post(
      "cashier/payment/validation/unclaim",
      validationRequest,
      {
        header: { "Content-Type": "application/json" },
      }
    );
  }

  async getTransactionStatus(cashierID) {
    return this.apiClient.get(`cashier/paymentprocessing/status/${cashierID}`);
  }

  async printReceipt(cashierID) {
    return this.apiClient.post(`cashier/receipt/${cashierID}`);
  }
}
