class CredentialService {
  constructor(apiClient) {
    this.client = apiClient;
  }

  async SendEmail(emailModel) {
    return await this.client.post(`contractcredentials/email`, emailModel, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async GetAccessCredentialsForAccessHolder(
    accessHolderID,
    contractID,
    limit,
    offset,
    searchTerm
  ) {
    return await this.client.get(
      `contractcredentials/${contractID}/accessholder/${accessHolderID}`,
      { params: { limit, offset, searchTerm } }
    );
  }

  async CreateCredentialForAccessHolder(
    accessHolderID,
    contractID,
    credential
  ) {
    return await this.client.post(
      `contractcredentials/${contractID}/accessholder/${accessHolderID}`,
      credential,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async BulkCreateValidations(bulkRequest) {
    return await this.client.post("validations/bulk", bulkRequest, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async CreateValidation(request) {
    return await this.client.post("validations", request, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async CreateValidationOffer(validationOffer) {
    return await this.client.post("validations/offers", validationOffer, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async DeleteValidationOffer(validationOfferID) {
    return await this.client.delete(
      `validations/offers/${parseInt(validationOfferID)}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async UpdateValidationOffer(vaidationOffer) {
    return await this.client.put("validations/offers", vaidationOffer, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateValidationOfferActive(request) {
    return await this.client.patch("validations/offers/updateactivestatus", request, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async UpdateCredentialForAccessHolder(
    accessHolderID,
    contractID,
    credential
  ) {
    return await this.client.put(
      `contractcredentials/${contractID}/accessholder/${accessHolderID}/credential`,
      credential,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async RemoveCredentialFromAccessHolder(
    accessHolderID,
    contractID,
    credentialID
  ) {
    return await this.client.put(
      `contractcredentials/${contractID}/accessHolder/${accessHolderID}/removecredential`,
      `"${credentialID}"`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async GetCredentialTypes(entityID) {
    return await this.client.get(`entitycredentialtypes/${entityID}`);
  }

  async getCredentialCounts(entityId, credentialType) {
    return await this.client.get(
      `contractcredentials/counts/${entityId}?credentialType=${credentialType}`
    );
  }

  async applyOnlineValidation(validationID, parkingTransactionID) {
    return await this.client.post("validations/claim", {
      validationID,
      parkingTransactionID,
      online: true,
    });
  }

  async unapplyOnlineValidation(validationID, parkingTransactionID) {
    return await this.client.patch("validations/online/unclaim", {
      validationID,
      parkingTransactionID,
    });
  }

  async applyValidation(validationID, parkingTransactionID) {
    return await this.client.post("validations/claim", {
      validationID,
      parkingTransactionID,
      online: false,
    });
  }

  async tryApplyValidation(entityId, validationId, parkingTransactionId) {
    return await this.client.post("validations/v2/claim", {
      entityId,
      validationId,
      parkingTransactionId,
      online: false,
    });
  }

  async tryApplyValidationWithinLimit(validationID, parkingTransactionID,entityID, validationMemo) {
    return await this.client.post("validations/V2/claim/globallimit", {
      validationID,
      parkingTransactionID,
      entityID,
      validationMemo,
    });
  }
  async unapplyValidation(validationID, parkingTransactionID) {
    return await this.client.patch("validations/unclaim", {
      validationID,
      parkingTransactionID,
    });
  }

  async unapplyValidations(validationIDs, parkingTransactionID) {
    return await this.client.patch("validations/unclaim/bulk", {
      validationIDs,
      parkingTransactionID,
    });
  }

  async GetCredentialInventory({
    entityID,
    limit,
    offset,
    searchTerm,
    credentialType,
    status,
    cancelToken,
  }) {
    return await this.client.get("contractcredentials", {
      cancelToken,
      params: {
        entityID,
        limit,
        offset,
        searchTerm,
        credentialType,
        status,
      },
    });
  }

  /**
   * OBSOLETE
   */
  async ValidatePayOnEntryCashier(
    credentialReference,
    entityID,
    credentialType,
    entryTime
  ) {
    return await this.client.get(
      `attendedcashiervalidatecredential/${credentialReference}?entityID=${entityID}&credentialType=${credentialType}&entryTime=${entryTime}`
    );
  }

  /**
   * OBSOLETE
   */
  async ValidateRoamingCashier(
    credentialReference,
    entityID,
    credentialType,
    entryTime
  ) {
    return await this.client.get(
      `roamingcashiervalidatecredential/${credentialReference}?entityID=${entityID}&credentialType=${credentialType}&entryTime=${entryTime}`
    );
  }

  /**
   * OBSOLETE
   */
  async ValidatePOE(credentialReference, entityID, credentialType, entryTime, shiftID, isRoaming) {
    return this.client.get(
      `poecashier/contractcredentials/validate/${credentialReference}?entityID=${entityID}&credentialType=${credentialType}&entryTime=${entryTime}&shiftID=${shiftID}&isRoaming=${isRoaming}`
    );
  }

  async validateCashierCredential(
    credentialReference,
    entityID,
    credentialType,
    entryTime
  ) {
    return this.client.get(
      `cashier/credential/validate/${credentialReference}?entityID=${entityID}&credentialType=${credentialType}&entryTime=${entryTime}`
    );
  }
}

export default CredentialService;
