export default class TicketmasterService {
  constructor (client) {
    this.apiClient = client;
  }

  async createDeviceRegistration(deviceRegistration) {
    try {
      const response = await this.apiClient.post('ticketmaster', deviceRegistration, {
        headers: { 'Content-Type': 'application/json' }
      })

      return (response);
    } catch (error) {
      throw error.response;
    }
  }
}