class GPIOService {
  constructor(client) {
    this.apiClient = client;
  }

  async createIOMapping(gpioMapping) {
    return await this.apiClient.post(`gpios/configuration`, gpioMapping, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getIOMappings(deviceID) {
    return await this.apiClient.get(`gpios/configuration/${deviceID}`);
  }

  async UpdateIOMapping(gpioMapping) {
    return await this.apiClient.put(`gpios/configuration`, gpioMapping, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async DeleteIOMapping(deviceID, ioMappingID) {
    return await this.apiClient.delete(`gpios/v2/configuration/${deviceID}/${ioMappingID}`);
  }

  async GetActionableEvents() {
    return await this.apiClient.get(`events/system`);
  }
}

export default GPIOService;
