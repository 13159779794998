class routineService {
  constructor(client) {
    this.apiClient = client;
  }

  async getRoutineByID(id) {
    return this.apiClient.get(`events/routine/${id}`);
  }

  async getRoutinesForEntity(entityId, limit, offset, searchTerm) {
    return this.apiClient.get(`events/${entityId}`, {
      params: { limit, offset, searchTerm },
    });
  }

  async deleteRoutine(routineId) {
    return this.apiClient.delete(`events?routineId=${routineId}`);
  }

  async updateRoutine(routine) {
    return this.apiClient.put("events", routine, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async createRoutine(routine) {
    return this.apiClient.post("events", routine, {
      headers: { "Content-Type": "application/json" },
    });  
  } 
}

export default routineService;
