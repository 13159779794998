class AuditService {
  constructor(client) {
    this.apiClient = client;
  }

  async createLoginAudit(username) {
    return await this.apiClient.post(
      `audit/authentication/login?username=${username}`
    );
  }

  async createLogoutAudit(username) {
    return await this.apiClient.post(
      `audit/authentication/logout?username=${username}`
    );
  }

  async createAuditRecord(auditDTO, version = "latest") {
    if (version === "v1.0"){
      return await this.apiClient.post(`audit`, auditDTO);
    } else {
      return await this.apiClient.post(`audit/v2.0`, auditDTO);
    }
  }
}

export default AuditService;
