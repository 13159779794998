export default class PayOnEntryService {
  constructor(client) {
    this.apiClient = client;
  }

  async getPOEOffers(entityID) {
    return this.apiClient.get(`payonentryoffer/${entityID}`);
  }

  async createPOEOffer(offer) {
    return this.apiClient.post("bff/payonentryoffer", offer, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updatePOEOffer(offer) {
    return this.apiClient.put("bff/payonentryoffer", offer, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deletePOEOffer(offerid) {
    return this.apiClient.delete(`payonentryoffer?offerId=${offerid}`);
  }
  
  async getImageByOfferId(offerID) {
    return this.apiClient.get(`payonentryoffer/${offerID}/image`, {
      responseType: "blob",
    });
  }

  async createEmptyParkingTransactionForPOE(cancelToken) {
    return await this.apiClient.post("poe/transaction", null, { cancelToken: cancelToken} );
  }

}