import EntityService from "./EntityService";

class DeviceService extends EntityService {
  constructor(client) {
    super(client);
  }

  async createEntity(parentEntityID, entity) {
    entity.name = entity.entityname;
    return await this.apiClient.post(
      `parkingdevice/${parentEntityID}`,
      entity,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async getEntities(parentEntityID, offset, limit, searchTerm) {
    return await this.apiClient.get(`parkingdevice/${parentEntityID}`, {
      params: { offset, limit, searchTerm },
    });
  }

  async getEntitiesByDeviceMode(parentEntityID, offset, limit, searchTerm, deviceMode) {
    return await this.apiClient.get(`parkingdevice/v2/${parentEntityID}/deviceMode/${deviceMode}/`, {
      params: { offset, limit, searchTerm },
    });
  }

  async getEntity(parentEntityID, deviceID) {
    return await this.apiClient.get(
      `parkingdevice/${parentEntityID}/${deviceID}`
    );
  }

  async updateEntity(entity) {
    entity.name = entity.entityname;
    entity.deviceID = entity.entityid;
    return await this.apiClient.put("parkingdevice", entity, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteEntities([data]) {
    data.name = data.entityname;
    return await this.apiClient.patch("parkingdevice", [data.entityid], {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getGroupedDevices(facilityID) {
    return await this.apiClient.get(`parkingdevice/groups/${facilityID}`);
  }

  async configureBNR(config) {
    return await this.apiClient.post("parkingdevice/configure/configurebnr", config, {
      headers: { "Content-Type": "application/json" },
    }
    );
  }

  async getAvailableCreditCardTerminals(facilityID) {
    return await this.apiClient.get(`roamingcreditcardterminal/${facilityID}`);
  }

  async getAvailableCashieredDevices(facilityID) {
    return await this.apiClient.get(`parkingdevice/cashiereddevices/${facilityID}`);
  }

  async printReceipt(printCommand) {
    return await this.apiClient.post('Cashier/Payment/Receipt',
      printCommand, {
      headers: { "Content-Type": "application/json" },
    }
    );
  }

  async getParkingDeviceNestSettings(deviceID) {
    return await this.apiClient.get(`parkingdevice/nestsettings/${deviceID}`);
  }

  async updateParkingDeviceNestSettings(
    deviceID, nestDestinationID, nestDestinationOverride, nestUpdateIOStatus
  ) {
    var data = {
      deviceID: deviceID,
      nestDestinationID: nestDestinationID,
      nestDestinationOverride: nestDestinationOverride,
      nestUpdateIOStatus: nestUpdateIOStatus
    };

    return await this.apiClient.patch("parkingdevice/nestsettings", data, {
      headers: { "Content-Type": "application/json" },
    });
  }
}

export default DeviceService;
