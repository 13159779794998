class ReversingLaneService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async getReversingLanes(entityID) {
    return await this.apiClient.get(`Reverse/lanes/${entityID}`);
  }

  async requestSwitchingLane(params) {
    return await this.apiClient.post(`Reverse/lane/${params.entityId}/${params.lanePairID}/${params.mode}`,
    {
      headers: { "Content-Type": "application/json" },
    });
  }

  async createReverseLane(params, laneSettings) {
    return await this.apiClient.post(`Reverse/lane/${params}`,
    laneSettings,
    {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateReverseLane(params, laneSettings) {
    return await this.apiClient.put(`Reverse/lane/${params}/${laneSettings.laneID}`,
    laneSettings,
    {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteReverseLane(params, laneID) {
    return await this.apiClient.delete(`Reverse/lane/${params}/${laneID}`);
  }

  async getReversingLanesByDeviceID(entityID) {
    return await this.apiClient.get(`Reverse/lane/device/${entityID}`);
  }

}
export default ReversingLaneService;