import EntityService from "./EntityService";

class AreaService extends EntityService {
  constructor(client) {
    super(client);
  }
  
  async createEntity(parentEntityID, entity) {
    entity.name = entity.entityname;
    entity.type = entity.entitytype;
    return await this.apiClient.post(`area/${parentEntityID}`, entity, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getEntities(parentEntityID, offset, limit, searchTerm) {
    return await this.apiClient.get(`area/${parentEntityID}`, {
      params: { offset, limit, searchTerm },
    });
  }

  async getEntity(parentEntityID, areaID) {
    return await this.apiClient.get(`area/${parentEntityID}/${areaID}`);
  }

  async updateEntity(entity) {
    entity.name = entity.entityname;
    entity.areaID = entity.entityid;
    entity.type = entity.entitytype;
    return await this.apiClient.put("area", entity, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteEntities([data]) {
    data.name = data.entityname;
    data.type = data.entitytype;
    return await this.apiClient.patch("area", [data.entityid], {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getNestAreas(entity) {
    return await this.apiClient.get(`area/${entity}/nest`);
  }
}

export default AreaService;