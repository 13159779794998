import moment from "moment-timezone";

class CreditCardOnFileService {
  constructor(client) {
    this.apiClient = client;
  }

  getClientTime() {
    var time = moment
      .utc(new Date())
      .local()
      .format();
    return time;
  }
  
  async getCreditCardOnFile(contractID) {
    return await this.apiClient.get(
      `CreditCardOnFile/GetDetails/${contractID}`
    );
  }

  async deleteCreditCardOnFile(contractID) {
    return await this.apiClient.delete(
      `CreditCardOnFile/DeleteDetails/${contractID}`
    );
  }

  async requestCreditCardToken(contractID, hostBase, totalToPay, externalService) {
    return await this.apiClient.post(
      'cardonfile/requesttoken',
      {
        contractID: contractID,
        hostBase: hostBase,
        totalToPay: totalToPay,
        externalService: externalService
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async getTransactionState(contractID) {
    return await this.apiClient.get(
      `cardOnfile/transactionstate/${contractID}`
    );
  }

  async deleteTransactionState(contractID) {
    return await this.apiClient.delete(
      `cardOnfile/transactionstate/${contractID}`
    );
  }

  async deleteTokens(facilityID) {
    return await this.apiClient.delete(
      `cardOnfile/deletetokens/${facilityID}`
    );
  }
}

export default CreditCardOnFileService;
