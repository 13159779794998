class UserService {
  constructor(client) {
    this.apiClient = client;
  }

  async getUsers(entityID, limit, offset, searchTerm = "") {
    return await this.apiClient.get("accounts", {
      params: {
        entityID: entityID,
        limit: limit,
        offset: offset,
        searchTerm: searchTerm,
      },
    });
  }

  async getAllUsersForValidationAccount(
    entityID,
    validationAccountID,
    searchTerm,
    showSystemUsersOnly,
    limit,
    offset
  ) {
    return await this.apiClient.get(
      `accounts/v2/validationaccount/${entityID}/${validationAccountID}`,
      {
        params: {
          searchTerm: searchTerm,
          showSystemUsersOnly: showSystemUsersOnly,
          limit: limit,
          offset: offset,
        },
      }
    );
  }

  async getUsersForValidationAccount(
    validationAccountID,
    searchTerm = "",
    userType = null
  ) {
    return await this.apiClient.get(
      `accounts/validation/${validationAccountID}`,
      { params: { searchTerm, userType } }
    );
  }

  async createUserForValidationAccount(user) {
    return await this.apiClient.post("validationaccounts", user, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getUserForValidationAccount(userId) {
    return await this.apiClient.get(`validationaccounts/${userId}`);
  }

  async updateUserForValidationAccount(user) {
    return await this.apiClient.put("validationaccounts", user, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteUserForValidationAccount(user) {
    return await this.apiClient.delete("validationaccounts", {
      headers: { "Content-Type": "application/json" },
      data: user
    });
  }

  async getUser(userId) {
    return await this.apiClient.get(`accounts/${userId}`);
  }

  async getUserByEmail(email) {
    return await this.apiClient.get(`accounts/user/${email}`);
  }

    async getUserExists(email) {
    return await this.apiClient.get(`accounts/exists/${email}`);
  }

  async createUser(user) {
    return await this.apiClient.post("accounts", user, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateUser(user) {
    return await this.apiClient.put("accounts", user, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateInfo(user) {
    return await this.apiClient.put("user/info/update", user, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteAdminUser(userId) {
    return await this.apiClient.delete(`accounts/${userId}`);
  }

  async deleteUser(entityID, userId) {
    return await this.apiClient.patch(`accounts/v2/${entityID}`, [userId], {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteUsers(entityID, userIds) {
    return await this.apiClient.patch(`accounts/v2/${entityID}`, userIds, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getEntityRoles(entityID, limit, offset, searchTerm) {
    return await this.apiClient.get("roles", {
      params: { entityID, limit, offset, searchTerm },
    });
  }

  async getAllPermissions() {
    return await this.apiClient.get("permissions");
  }

  async getScopedPermissions(userID, entityID) {
    return await this.apiClient.get(`accounts/permissions/${userID}`);
    //return await this.apiClient.get(`accounts/permissions/${userID}?entityID=${entityID}`);
  }

  async getScopedPermissionsWithEntity(userID, entityID) {
    return await this.apiClient.get(
      `accounts/permissions/${userID}?entityID=${entityID}`
    );
  }

  async deleteRoles(roleIDs) {
    return await this.apiClient.patch("roles/deleteRoles", roleIDs, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getRole(roleID) {
    return await this.apiClient.get(`roles/${roleID}`);
  }

  async updateRole(role) {
    return await this.apiClient.put("roles", role, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteRole(roleID) {
    return await this.apiClient.delete(`roles/${roleID}`);
  }

  async createRole(role) {
    return await this.apiClient.post("roles", role, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async getUserGroups(entityID, limit, offset, searchTerm) {
    return await this.apiClient.get("groups", {
      params: { entityID, limit, offset, searchTerm },
    });
  }

  async getUserGroup(groupID) {
    return await this.apiClient.get(`groups/${groupID}`);
  }

  async createUserGroup(group) {
    return await this.apiClient.post("groups", group, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async deleteUserGroups(groupIDs) {
    return await this.apiClient.patch("groups/deleteGroups", groupIDs, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async updateUserGroup(group) {
    return await this.apiClient.put("groups", group, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async resendUserInvitation(user) {
    return await this.apiClient.post("accounts/reset", user, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async isUserAdmin(userID) {
    return await this.apiClient.get(`accounts/isadmin/${userID}`);
  }

  async assignUserGroups(user) {
    return await this.apiClient.patch("accounts/assignUserGroups", user, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async GetUsersWithTheirAdminGroupStatus(limit, offset, searchTerm = "") {
    return await this.apiClient.get("accounts/adminGroupGet", {
      params: {
        limit: limit,
        offset: offset,
        searchTerm: searchTerm,
      },
    });
  }

  async GetAuditHistory(
    entityid,
    categories,
    emailSearchTerm,
    fromDate,
    toDate
  ) {
    return await this.apiClient.post(`audithistory/${entityid}`, categories, {
      params: {
        emailSearchTerm: emailSearchTerm,
        fromDate: fromDate,
        toDate: toDate,
      },
      headers: { "Content-Type": "application/json" },
    });
  }

  async GetIntegratorCredentials(integratorId) {
    return await this.apiClient.get(`integrators/credentials/${integratorId}`);
  }
}

export default UserService;
