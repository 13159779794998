import { PAYMENT_GATEWAY } from "../constants";

class FPAPaymentService {
  constructor(apiClient) {
    this.client = apiClient;
  }

  async getFPAAccountDetails(accessHolderId, email) {
    return await this.client.get(`FPAPay/GetDetails/${accessHolderId}`, {
      params: { email: email }
    });
  }

  async postFPARequestPayment(accessHolderId, hostBase, totalToPay, externalService) {
    return await this.client.post(`fpa/requestpayment`,
      {
        accessHolderID: accessHolderId,
        hostBase: hostBase,
        totalToPay: totalToPay,
        externalService: externalService
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async postFPAEmailReceipt(accessHolderId, email, amiReferenceId) {
    return await this.client.post(`FPAPay/EmailReceipt/${accessHolderId}`,
      {},
      { params: { email: email, amiReferenceId: amiReferenceId }}
    );
  }
}

export default FPAPaymentService;