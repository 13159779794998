class ContactService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async createContact(contact) {
    return await this.apiClient.post("contacts", contact, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getContacts(entityID, limit, offset, searchTerm) {
    return await this.apiClient.get("contacts", {
      params: { entityID, limit, offset, searchTerm },
    });
  }

  async updateContact(contact) {
    return await this.apiClient.put("contacts", contact, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getContact(contactID) {
    return await this.apiClient.get(`contacts/${contactID}`);
  }

  async deleteContacts(contactIDs) {
    return await this.apiClient.patch("contacts", contactIDs, {
      headers: { "Content-Type": "application/json" },
    });
  }
}

export default ContactService;
