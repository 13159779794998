export default class ScheduledReportsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async getScheduledReports(facilityID) {
    return await this.apiClient.get(`scheduledreports/${facilityID}`);
  }

}

