class AutomaticValidationService {
  constructor(client) {
    this.apiClient = client;
  }

  async getAutomaticValidation(userId, entityId) {
    return await this.apiClient.get(`automatic-validations`, {
      params: {
        userId: userId,
        entityId: entityId
      },
    });
  }
}

export default AutomaticValidationService;
