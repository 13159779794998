class CountService {
    constructor(client) {
      this.apiClient = client;
    }

    async createOccupancySupply(occupancySupplyList) {
      return await this.apiClient.post(`counts/supply/occupancy`, occupancySupplyList, {headers: {"Content-Type": "application/json"}});
    }

    async getOccupancyTypes(searchTerm) {
      return await this.apiClient.get(`counts/type/`, searchTerm, {headers: {"Content-Type": "application/json"}});
    }
    async GetOccupancySupplyByEntity(entityID) {
      return await this.apiClient.get(`counts/supply/occupancy/${entityID}`, {headers: {"Content-Type": "application/json"}});
    }

    async GetOccupancyDemandByEntity(entityID) {
      return await this.apiClient.get(`counts/demand/occupancy/${entityID}`, {headers: {"Content-Type": "application/json"}});
    }

    async GetOccupancyInfoByEntity(entityID, cancelToken) {
			return await this.apiClient.post(`occupancyInfo`, [
				entityID
			], cancelToken);
    }

    async UpdateOccupancySupply(occupancySupplyList) {
      return await this.apiClient.put(`counts/supply/occupancy`, occupancySupplyList, {headers: {"Content-Type": "application/json"}})
    }

    async DeleteOccupancySupply(occupancySupplyIDList) {
      return await this.apiClient.delete(`counts/supply/occupancy`, occupancySupplyIDList, {headers: {"Content-Type": "application/json"}})
    }

    async UpsertOccupancyDemand(occupancyDemand) {
      return await this.apiClient.post(`counts/demand`, occupancyDemand, {headers: {"Content-Type": "application/json"}})
    }

    async GetTrackedAccessGroups(entityID) {
      return await this.apiClient.get(`counts/accessgroups/${entityID}/`, {headers: {"Content-Type": "application/json"}});
    }

    async GetThresholdsForEntity(entityID) {
      return await this.apiClient.get(`threshold/${entityID}`, {headers: {"Content-Type": "application/json"}})
    }

    async getThresholdsByOccupancyTypeForEntity(entityID, occupancyTypeID) {
      return await this.apiClient.get(`threshold/${entityID}/${occupancyTypeID}`, {headers: {"Content-Type": "application/json"}})
    }

    async upsertThreshold(thresholdList) {
      return await this.apiClient.post(`threshold/v2`, thresholdList, {headers: {"Content-Type": "application/json"}})
    }
  }

  export default CountService;
