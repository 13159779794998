class MaintananceWindowService {
    constructor(client){
        this.apiClient = client;
    }

    async getMaintenanceWindows(facilityId){
       return this.apiClient.get(`maintenancewindow/${facilityId}`);
    }

    async rescheduleMaintenanceWindow(facilityId, day, time, duration,timeZoneId){
        return await this.apiClient.put(`maintenancewindow/${facilityId}/schedule`,
            {
                startDay: day,
                timeSpan: time,
                duration: duration,
                timeZoneId: timeZoneId,
                isEnabled: true
            }
        );
    }

    async updateMaintenanceStatus(facilityId, day, time, duration,timeZoneId, isEnabled){
        return await this.apiClient.put(`maintenancewindow/${facilityId}/schedule`,
            {
                startDay: day,
                timeSpan: time,
                duration: duration,
                timeZoneId: timeZoneId,
                isEnabled: isEnabled
            }
        );
    }
}

export default MaintananceWindowService;
