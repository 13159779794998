class taxesService {
  constructor(client) {
    this.apiClient = client;
  }

  async getPageableTaxes(entityID, limit, offset, searchTerm) {
    return await this.apiClient.get("taxes", {
      params: { entityID, limit, offset, searchTerm },
    });
  }

  async getTax(taxID) {
    return await this.apiClient.get(`taxes/${taxID}`);
  }

  async createTax(entityId, taxInfo) {
    
    return await this.apiClient.post(`taxes/${entityId}`, taxInfo, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async updateTax(entityId, taxInfo) {
    
    return await this.apiClient.put(`taxes/${entityId}`, taxInfo, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async deleteTaxes(entityId, taxIDs) {
    return await this.apiClient.patch(`taxes/${entityId}`, taxIDs, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default taxesService;
