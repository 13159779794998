class ReportService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getReports(facilityID) {
    return this.apiClient.get("/reports", {
      params: { facilityID: facilityID },
    });
  }

  getReportAttributes(reportID, facilityID) {
    return this.apiClient.get(`/reports/${reportID}/attributes`, {
      params: { facilityID },
    });
  }

  downloadReport(reportID, reportFilters, facilityID, outputType) {
    return this.apiClient.post(`/reports/download/${reportID}`, reportFilters, {
      params: { facilityID, outputType: outputType },
      responseType: "arraybuffer",
    });
  }
}

export default ReportService;
