class guesttypeService {
  constructor(client) {
    this.apiClient = client;
  }

  async getGuestTypes(entityId) {
    return this.apiClient.get(`guesttype/${entityId}`);
  }

  async createGuestType(guesttype) {
    return this.apiClient.post(`guesttype`, guesttype, {
      headers: { "Content-Type": "application/json" }
    });
  }

  async deleteGuestType(guestTypeId) {
    return this.apiClient.delete(`guesttype`, {
      headers: { "Content-Type": "application/json" },
      data: guestTypeId,
    });
  }

  async updateSingleGuestType(guesttype) {
    return this.apiClient.put(`guesttype`, guesttype, {
      headers: { "Content-Type": "application/json" }
    });
  }

  async getSystemGuestTypes() {
    return this.apiClient.get(`guesttype/systemguesttypes`);
  }

  async isGuestTypeUsed(guestTypeID) {
    return this.apiClient.get(`valetguesttype/isinuse/${guestTypeID}`);
  }


}

export default guesttypeService;
