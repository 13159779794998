class peripheralService {
    constructor(client) {
      this.apiClient = client;
    }
  
    async getPeripheralCounts(entityId) {
      return this.apiClient.get(`peripheral/count/${entityId}`);
    }

    async getPeripherals(entityId) {
      return this.apiClient.get(`peripheral/${entityId}?includeInactive=true`);
    }

    async updatePeripherals(peripherals) {
      return this.apiClient.put(`peripheral`, peripherals);
    }

    async addSettingsToExistingPeripheral(peripheral) {
        return this.apiClient.patch(`peripheral`, peripheral);
    }

  async createPeripheral(peripheral) {
    return this.apiClient.post(`peripheral`, peripheral, {
      headers: { "Content-Type": "application/json" }
    });
  }

  async deletePeripheral(entityId, peripheralId) {
    return this.apiClient.delete(`peripheral/${peripheralId}?deviceid=${entityId}`, {
      headers: { "Content-Type": "application/json" }
    });
  }

  async updateSinglePeripheral(peripheral) {
    return this.apiClient.put(`peripheral/${peripheral?.peripheralID}`, peripheral, {
      headers: { "Content-Type": "application/json" }
    });
  }
  }

  export default peripheralService;
  