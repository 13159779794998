export default class PayOnEntryOfferScheduleService {
  constructor (client) {
    this.apiClient = client;
  }

  async getPOEOfferScheduleByEntityId(entityId, start, end) {
    return this.apiClient.get(`payonentryofferschedule/${entityId}?start=${start}&end=${end}`, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getPOEOfferScheduleByOfferId(offerId) {
    return this.apiClient.get(`payonentryofferschedule/offer/${offerId}`, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async createPOEOfferSchedule(poeOfferSchedule) {
    return this.apiClient.post('payonentryofferschedule', poeOfferSchedule, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updatePOEOfferSchedule(poeOfferSchedule) {
    return this.apiClient.put('payonentryofferschedule', poeOfferSchedule, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deletePOEOfferSchedule(scheduleId) {
    return this.apiClient.delete(`payonentryofferschedule/${scheduleId}`)
  }
}