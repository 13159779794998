import EntityService from "./EntityService";

class LaneService extends EntityService {
  constructor(client) {
    super(client);
  }

  async createEntity(parentEntityID, entity) {
    entity.name = entity.entityname;
    return await this.apiClient.post(`lane/${parentEntityID}`, entity, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getEntities(parentEntityID, offset, limit, searchTerm) {
    return await this.apiClient.get(`lane/${parentEntityID}`, {
      params: { offset, limit, searchTerm },
    });
  }

  async getEntity(parentEntityID, laneID) {
    return await this.apiClient.get(`lane/${parentEntityID}/${laneID}`);
  }

  async updateEntity(entity) {
    entity.name = entity.entityname;
    entity.LaneID = entity.entityid;
    return await this.apiClient.put("lane", entity, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async deleteEntities([data]) {
    data.name = data.entityname;
    return await this.apiClient.patch("lane", [data.entityid], {
      headers: { "Content-Type": "application/json" },
    });
  }
}

export default LaneService;