class ThirdpartyVendorService {
    constructor(apiClient) {
      this.apiClient = apiClient;
    }

    async getThirdParty(subscriberId, entityID, status) {
      let endpoint = `vendor?`;

      let params = new URLSearchParams();

      if(subscriberId)
        params.append('subscriberid', subscriberId);

      if(entityID)
        params.append('entityid', entityID);

      if(status)
        params.append('status', status);

      return await this.apiClient.get(endpoint + params.toString());
    }

    async createThirdParty(ThirdPartyInfo) {
      return await this.apiClient.post(`vendor`, ThirdPartyInfo, {headers: {"Content-Type": "application/json"}});
    }

    async updateThirdParty(ThirdPartyInfo) {
      return await this.apiClient.put(`vendor`, ThirdPartyInfo, {headers: {"Content-Type": "application/json"}});
    }
    
    async createEntitySubscriber(entitySubscriber) {
      return await this.apiClient.post(`vendor/entitySubscriber`, entitySubscriber, {headers: {"Content-Type": "application/json"}});
    }

    async updateEntitySubscriber(entitySubscriber) {
      return await this.apiClient.put(`vendor/entitySubscriber`, entitySubscriber, {headers: {"Content-Type": "application/json"}});
    }

    async getThirdPartyPassesPerSubscriber(entityID) {
      return await this.apiClient.get(`vendor/passespervendor/${entityID}`);
    }

    async getThirdPartyIntegrators(entityID) {
      return await this.apiClient.get(`vendor?entityID=${entityID}`);
    }

    async getSubscriberPermissions() {
      return await this.apiClient.get(`vendor/permissions`);
    }
  }
  
  export default ThirdpartyVendorService;
  