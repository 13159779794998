class applyTaxesService {
  constructor(client) {
    this.apiClient = client;
  }

  async getAppliedTaxesForEntity(entityID) {
    return await this.apiClient.get(`taxes/apply-entity/${entityID}`);
  }

  async applyTaxesToEntity(entityID, applyTaxes) {
    return await this.apiClient.post(`taxes/apply-entity/${entityID}`, applyTaxes, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async deleteAppliedTaxesForEntity(entityID) {
    return await this.apiClient.patch(`taxes/apply-entity`, [entityID], {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default applyTaxesService;
