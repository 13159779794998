class taxRatesService {
  constructor(client) {
    this.apiClient = client;
  }

  async getPageableTaxRates(entityID, limit, offset, searchTerm) {
    return await this.apiClient.get("tax-rates", {
      params: { entityID, limit, offset, searchTerm },
    });
  }

  async getTaxRate(taxRateID) {
    return await this.apiClient.get(`tax-rates/${taxRateID}`);
  }

  async createTaxRate(entityId, taxRateInfo) {
    const request = {
      description: taxRateInfo.description,
      taxAmount: parseFloat(taxRateInfo.taxAmount),
      entityId: taxRateInfo.entityID,
    };
    return await this.apiClient.post(`tax-rates/${entityId}`, request, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async updateTaxRate(entityId, taxRateInfo) {
    const request = {
      taxRateID: taxRateInfo.taxRateID,
      description: taxRateInfo.description,
      taxAmount: parseFloat(taxRateInfo.taxAmount),
      entityId: taxRateInfo.entityID,
    };
    return await this.apiClient.put(`tax-rates/${entityId}`, request, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async deleteTaxRates(entityId, taxRateIDs) {
    return await this.apiClient.patch(`tax-rates/${entityId}`, taxRateIDs, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default taxRatesService;
