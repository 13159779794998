class CreditCardGatewayService {
  constructor(client) {
    this.apiClient = client;
  }

  async createCreditCardGatewaySetting(gatewaySetting) {
    return await this.apiClient.post(`gateway/setting`, gatewaySetting, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getCreditCardGateways(gatewayName, gatewayID) {
    return await this.apiClient.get(`gateway`, {
      params: { gatewayName, gatewayID },
    });
  }

  async CreateCreditCardGatewayEntityMapping(gatewayEntityMapping) {
    return await this.apiClient.post(
      `gateway/entitymapping`,
      gatewayEntityMapping,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async getCreditCardGatewaySettingsByEntityID(entityID) {
    return await this.apiClient.get(`gateway/setting/entity/${entityID}`, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateCreditCardGatewaySettings(gatewaySettings) {
    return await this.apiClient.put(`gateway/setting`, gatewaySettings, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async upsertSettings(entityID, gatewaySettings) {
    return await this.apiClient.put(`gateway/setting`, gatewaySettings, {
      params: { entityID },
      headers: { "Content-Type": "application/json" },
    });
  }
}

export default CreditCardGatewayService;
