class EntityService {
  constructor(client) {
    this.apiClient = client;
  }

  async createEntity(parentEntityID, payload) {}

  async getEntities(parentEntityID, offset, limit, searchTerm) {}

  async getEntity(_, entityID) {
    return await this.apiClient.get(`entities/basic/${entityID}`);
  }

  async updateEntity(entity) {}

  async deleteEntities([data]) {}

  async getRichEntity(_entityID) {
    return await this.apiClient.get(`entities/${_entityID}/rich`);
  }

  async getAllowableChildren(entityID) {
    return await this.apiClient.get(`entities/${entityID}/allowedchildren`);
  }

  async getScope(entityID) {
    return await this.apiClient.get(`entities/${entityID}/scope`);
  }

  async getPropertiesByScopes(searchTerm = "") {
    return await this.apiClient.get("properties", {
      params: { propertyName: searchTerm },
    });
  }

  async getEntityChildren(parentPropertyID, entityTypes) {
    const searchParams = new URLSearchParams();

    entityTypes.forEach((param) => {
      searchParams.append("entityTypes", param);
    });

    return await this.apiClient.get(
      `properties/getChildren/${parentPropertyID}/`,
      {
        params: searchParams,
      }
    );
  }
}

export default EntityService;
