import {TICKET_FIELD_REQUEST_TYPE } from "../constants";

class TicketFieldService {
  constructor(client) {
    this.apiClient = client;
  }

  async getTicketFields(ticketFieldObj) {
    return await this.apiClient.get(`ticketfield/fieldtype/${ticketFieldObj.requestType}`);
  }

  async getEntityTicketFields(entityFieldObj) {
    return this.apiClient.get(`entityticketfield/${entityFieldObj.entityID}/fieldType/${entityFieldObj.requestType}`);
  }

  async createEntityTicketField(entityFieldObj) {
    return await this.apiClient.post(`entityticketfield`, entityFieldObj);
  }

  async updateTicketField(entityFieldObj) {
    return await this.apiClient.put(`entityticketfield`, entityFieldObj); 
  }

  async deleteEntityTicketField(entityFieldObj) {
    return this.apiClient.delete(`entityticketfield`, {
      headers: { "Content-Type": "application/json" },
      data: entityFieldObj,
    });
  }

  getRequestName(requestType) {
    switch (requestType) {
      case TICKET_FIELD_REQUEST_TYPE.ARRIVAL:
        return "Arrival";
      case TICKET_FIELD_REQUEST_TYPE.REQUEST:
        return "Request";
      // Handle additional request types here
      default:
        return "";
    }
  }

  async updateTicketFieldOrder(payload) {
    return await this.apiClient.put(`entityticketfield/bulkupdateorder`, payload);
  }
  

}

export default TicketFieldService;
